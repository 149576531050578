import Searchbar from "./Searchbar";

function Header() {
  return (
    <>
      <Searchbar />
    </>
  );
}

export default Header;
