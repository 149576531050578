import { useState, useContext } from "react";
import EcommerceContext from "../../context/EcommerceContext";
import { FaCartArrowDown } from "react-icons/fa6";
import { MdOutlineLiveHelp } from "react-icons/md";
import { RiAccountPinCircleLine, RiAccountPinCircleFill } from "react-icons/ri";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { NavLink } from "react-router-dom";
import Accountslide from "./Accountslide";
import Helpslide from "./Helpslide";
// import { useNavigate } from "react-router-dom";

function Headerlist() {
  // const navigate = useNavigate();
  const [accslide, setAccslide] = useState(false);
  const [helpslide, setHelpslide] = useState(false);
  const { cartlist, isAuthenticated, cartlistServer } =
    useContext(EcommerceContext);

  const fromserverlist = cartlistServer.cartlist;

  let cartFill = [];

  if (isAuthenticated) {
    cartFill = fromserverlist || [];
  } else {
    cartFill = cartlist || [];
  }
  return (
    <ul className="mainnav">
      <li onClick={() => setAccslide(!accslide)}>
        <NavLink>
          {isAuthenticated ? (
            <RiAccountPinCircleFill
              className="headericonsize"
              size={"2.5rem"}
            />
          ) : (
            <RiAccountPinCircleLine
              className="headericonsize"
              size={"2.5rem"}
            />
          )}{" "}
          <span>Account</span>{" "}
          {accslide ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </NavLink>
        {accslide && <Accountslide />}
      </li>
      <li onClick={() => setHelpslide(!helpslide)}>
        <NavLink>
          <MdOutlineLiveHelp className="headericonsize" size={"2.5rem"} />{" "}
          <span>Help</span> {helpslide ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </NavLink>
        {helpslide && <Helpslide />}
      </li>
      <li>
        <NavLink to="/cart">
          <FaCartArrowDown className="headericonsize" size={"2.5rem"} />
          <span className="cartnumber">
            {cartFill.length > 0 && cartFill.length}
          </span>
          <span>Cart</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default Headerlist;
