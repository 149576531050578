import { TailSpin } from "react-loader-spinner";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function PayLoading() {
  const navigate = useNavigate();

  useEffect(() => {
    // Assume payment is successful and navigate to Payment status
    setTimeout(() => {
      navigate("/paymentstatus");
    }, 5000); // Redirect after 5 seconds
  }, [navigate]);

  return (
    <div className="payloading">
      <div className="loading">
        <p>Redirecting to Paystack checkout...</p>
        {/* You can add a loader or any other content here */}
        <TailSpin
          height="60"
          width="60"
          color="#808080"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            width: "60px",
            margin: "auto",
            fontWeight: "bolder",
          }}
          wrapperClass=""
          visible={true}
        />
      </div>
    </div>
  );
}

export default PayLoading;
