import { useContext } from "react";
import { NavLink } from "react-router-dom";
import EcommerceContext from "../context/EcommerceContext";
import { TbCurrencyNaira } from "react-icons/tb";

function AllproductsDisplay({ item }) {
  const { aproductHandler } = useContext(EcommerceContext);
  return (
    <NavLink to={`/product/${item.id}`}>
      <div
        className="allproductsdisplay"
        onClick={() => aproductHandler(item.id)}
      >
        <img src={item.image} alt={item.productname} width={200} height={200} />
        <p>{item.brandname}</p>
        <h3>{item.productname}</h3>
        <h4>
          <TbCurrencyNaira className="tballproducts" size={"1.5rem"} />
          {item.price}
        </h4>
        <p>{item.rating}</p>
      </div>
    </NavLink>
  );
}

export default AllproductsDisplay;
