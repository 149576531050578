import { NavLink } from "react-router-dom";
import { IoMdArrowForward } from "react-icons/io";

function Allproductline() {
  return (
    <div className="productline">
      <div className="productbanner">
        <h2>Products</h2>
        <NavLink to="/allproducts">
          <h3>
            See all <IoMdArrowForward />
          </h3>
        </NavLink>
      </div>
    </div>
  );
}

export default Allproductline;
