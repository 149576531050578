import EcommerceContext from "../context/EcommerceContext";
import { useContext } from "react";
import Card from "./Card";

function ProductSection() {
  const { prod } = useContext(EcommerceContext);
  return (
    <div className="ProductSectionBack">
      <div className="ProductSection">
        {prod.map((item) => (
          <Card key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}

export default ProductSection;
