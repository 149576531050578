import { useState, useEffect, useContext } from "react";
import { TailSpin } from "react-loader-spinner";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import EcommerceContext from "../../context/EcommerceContext";
import queryString from "query-string";

function PaymentStatus() {
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState("pending");
  const { getAnOrderHandler, reloadcartserverhandler } =
    useContext(EcommerceContext);
  const [query, setQuery] = useState("");
  //   console.log(query);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    setQuery(parsed.reference);
  }, [location.search]);

  useEffect(() => {
    const checkPaymentStatus = async (query) => {
      if (query === undefined || query === "") {
        return;
      }
      try {
        const tokened = localStorage.getItem("req_saved_tokened_set");
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(
          `${apiUrl}/payment/status-confirmation/${query}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokened}`,
            },
          }
        );
        const data = await response.json();
        // console.log(data);
        setPaymentStatus(data.status);
      } catch (error) {
        const bebe = error.json();
        console.error("Error fetching payment status:", bebe);
      }
    };

    // Initially check payment status when component mounts
    checkPaymentStatus(query);

    // Poll for payment status every few seconds
    const interval = setInterval(checkPaymentStatus, 5000);

    // Cleanup function
    return () => clearInterval(interval);
  }, [query]);

  if (paymentStatus === "success") {
    const parsed = queryString.parse(location.search);
    setTimeout(() => {
      getAnOrderHandler(parsed.reference);
      reloadcartserverhandler();
      navigate("/orderedreciept");
    }, 5000);
  }

  return (
    <div className="paymentstatus">
      <div className="paymentloading">
        {paymentStatus === "success" && (
          <>
            <p>Your payment was successful!</p>
            <p>redirect in 2 seconds...</p>
            {/* You can redirect the user, display a success message, etc. */}
          </>
        )}
        {paymentStatus === "pending" && (
          <>
            <p>Your payment is pending. Please wait for confirmation.</p>
            {/* You can display a message indicating that the payment is pending */}
            <TailSpin
              height="60"
              width="60"
              color="#808080"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{
                width: "60px",
                margin: "auto",
                fontWeight: "bolder",
              }}
              wrapperClass=""
              visible={true}
            />
          </>
        )}
        {paymentStatus === "failed" && (
          <>
            <p>Your payment failed. Please try again.</p>
            {/* You can display a message indicating that the payment failed */}
            <NavLink to="/">
              <button className="btnsubmit">Home page</button>
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
}

export default PaymentStatus;
