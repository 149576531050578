import { TfiEmail } from "react-icons/tfi";
import { useState } from "react";

function ContactUs() {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [msgBody, setMsgBody] = useState("");

  const handleMobileChange = (e) => {
    const inputVal = e.target.value;
    // Regular expression to match only numbers
    const regex = /^[0-9\b]+$/;
    if (inputVal === "" || (regex.test(inputVal) && inputVal.length <= 11)) {
      setPhonenumber(inputVal);
    }
  };

  const createFormHandler = async (e) => {
    // const tokened = localStorage.getItem("req_saved_tokened_set");
    // const apiUrl = process.env.REACT_APP_API_URL;
    e.preventDefault();
    // const response = await fetch(`${apiUrl}/`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     // Authorization: `Bearer ${tokened}`,
    //   },
    //   body: JSON.stringify({
    //     fullname,
    //     email,
    //     phonenumber,
    //     msgBody,
    //   }),
    // });

    // const data = await response.json();
  };

  return (
    <div className="intouch">
      <div className="intouch-container">
        <TfiEmail className="emaillogo" />
        <h2>Get in touch</h2>
        <p>We'd love to hear from you</p>
        <form className="contactform" onSubmit={createFormHandler}>
          <input
            onChange={(e) => setFullname(e.target.value)}
            type="text"
            value={fullname}
            required
            placeholder="  Full Name"
          />
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            value={email}
            required
            placeholder="  Email Address"
          />
          <input
            onChange={handleMobileChange}
            type="text"
            value={phonenumber}
            required
            placeholder="  Phone number"
          />
          <textarea
            className="textarea"
            onChange={(e) => setMsgBody(e.target.value)}
            name="message"
            id="contactmessage"
            cols="30"
            rows="10"
            required
            value={msgBody}
          >
            Message
          </textarea>
          <button className="btnsubmit" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
