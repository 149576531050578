import { useState } from "react";
import { useContext } from "react";
import EcommerceContext from "../../context/EcommerceContext";
import { TailSpin } from "react-loader-spinner";
// import { useNavigate } from "react-router-dom";

function VerifyOTP() {
  // const navigate = useNavigate();
  const [otpCode, setOtpCode] = useState("");
  //   const [codeStatus, setCodeStatus] = useState(true);
  const { emaillogged, emailHandler } = useContext(EcommerceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [mess, setMess] = useState("");
  const [codeStatus, setCodeStatus] = useState(false);
  const [resend, setResend] = useState(false);

  const sendCodeHandler = () => {
    setIsLoading(true);
    setTimeout(() => {
      //   addCartHandler(aprod.id)
      const tokened = localStorage.getItem("req_saved_tokened_set");
      const apiUrl = process.env.REACT_APP_API_URL;
      fetch(`${apiUrl}/otp/generate-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokened}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // console.log(data);
          if (data.msg) {
            setMess(data.msg);
            // updateAuth(true);
            setTimeout(() => {
              setCodeStatus(true);
            }, 1000);
          } else {
            setMess(data.error);
            // localStorage.removeItem("req_saved_tokened_set");
            // updateAuth(false);
          }
        });
    }, 1000);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Regular expression to match only numbers
    const regex = /^[0-9\b]+$/;
    if (
      inputValue === "" ||
      (regex.test(inputValue) && inputValue.length <= 6)
    ) {
      setOtpCode(inputValue);
    }
  };

  const verifyOTPhandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      //   addCartHandler(aprod.id);
      //   console.log(otpCode);
      const tokened = localStorage.getItem("req_saved_tokened_set");
      const apiUrl = process.env.REACT_APP_API_URL;
      fetch(`${apiUrl}/otp/verify-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokened}`,
        },
        body: JSON.stringify({ collectedotp: otpCode }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // console.log(data);
          if (data.msg === "Email Verified via OTP") {
            setMess(data.msg);
            setTimeout(() => {
              emailHandler(data.emailVerify);
              // localStorage.setItem(
              //   "req_saved_email_verified",
              //   JSON.stringify(data.emailVerify)
              // );
              // console.log(data);
            }, 1000);
            // setTimeout(() => {
            //   navigate("/registerprofile");
            // }, 2000);
            // updateAuth(true);
          } else {
            setMess(data.msg);
            // localStorage.removeItem("req_saved_tokened_set");
            // updateAuth(false);
          }
        });
    }, 1000);
    setTimeout(() => {
      setIsLoading(false);
      setOtpCode("");
    }, 2000);
  };

  setTimeout(() => {
    setResend(true);
  }, 120000);

  return (
    <div className="otppopup">
      <div className="otppopinner">
        {codeStatus ? (
          <>
            <p>
              A six digit verification code has been sent to your email -
              <u>{emaillogged || "*****"}</u>. This code expires in 30 minutes.
            </p>
            <p>Enter your code in the box below;</p>
            <form onSubmit={verifyOTPhandler}>
              <input
                onChange={handleChange}
                type="text"
                value={otpCode}
                required
              />
              <div className="otpbuttonbox">
                <button>
                  {isLoading ? (
                    <TailSpin
                      height="25"
                      width="25"
                      color="#fff"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{
                        width: "100px",
                        margin: "auto",
                        fontWeight: "bolder",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : (
                    "Verify"
                  )}
                </button>
                {resend && (
                  <button onClick={sendCodeHandler}>
                    {isLoading ? (
                      <TailSpin
                        height="25"
                        width="25"
                        color="#fff"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{
                          width: "100px",
                          margin: "auto",
                          fontWeight: "bolder",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      "Resend Code"
                    )}
                  </button>
                )}
              </div>
              <p>{mess}</p>
              <p>Check junk box too. Resend button appear after 2minutes</p>
            </form>
          </>
        ) : (
          <>
            <h4>
              Welcome to Uptuned shop, the best place to get your music
              instrument.
            </h4>
            <p>
              Please, verify your email to have full access to our service and
              to recieve mails on all your activity. Click on the link below to
              recieve your verification code.
            </p>
            <button onClick={sendCodeHandler}>
              {isLoading ? (
                <TailSpin
                  height="25"
                  width="25"
                  color="#fff"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{
                    width: "100px",
                    margin: "auto",
                    fontWeight: "bolder",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                "Send Code"
              )}
            </button>
            <p>{mess}</p>
          </>
        )}
      </div>
    </div>
  );
}

export default VerifyOTP;
