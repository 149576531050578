import { BsCart2 } from "react-icons/bs";
import { NavLink } from "react-router-dom";

function Emptycart() {
  return (
    <div className="emptycart">
      <BsCart2 className="emptycartlogo" />
      <h3>Cart is Empty.</h3>
      <h4>
        Click{" "}
        <NavLink to="/allproducts" style={{ color: "blue" }}>
          here
        </NavLink>{" "}
        to start shopping.
      </h4>
    </div>
  );
}

export default Emptycart;
