import { createContext, useState, useEffect } from "react";

//Context
const EcommerceContext = createContext();

//Provider
export const EcommerceProvider = ({ children }) => {
  const cartLocal = localStorage.getItem("cartLocal");
  const auditCart = cartLocal ? JSON.parse(cartLocal) : [];
  //or can be written as below
  // const getemailverify =
  // JSON.parse(localStorage.getItem("req_saved_email_verified")) || null;

  const [prod, setProd] = useState([]);
  const [aprod, setAprod] = useState({
    id: "",
    productname: "",
    brand: "",
    price: "",
    rating: "",
    featuredproduct: "",
    quantity: "",
    image: "",
    description: "",
  });
  const [cartlist, setCartlist] = useState(auditCart);
  const [cartlistServer, setCartlistServer] = useState({});
  const [subtotal, setSubtotal] = useState("");
  // const [quant, setQuant] = useState(1);
  const [isLoadAPI, setIsLoadAPI] = useState(true);
  const [loginMsg, setLoginMsg] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(Boolean);
  const [emailverify, setEmailVerify] = useState(Boolean);
  const [emaillogged, setEmailLogged] = useState("");
  const [checkoutNumbers, setCheckoutNumbers] = useState({});
  const [profiledetails, setProfiledetails] = useState({});
  const [anOrder, setAnOrder] = useState({});
  const [getOrder, setGetOrder] = useState({});
  const [allOrders, setAllOrders] = useState([]);

  // console.log(isLoadAPI);

  //Fecth all product
  useEffect(() => {
    datafecther();
    // updateAuth(true);
    const tokened = localStorage.getItem("req_saved_tokened_set");
    const apiUrl = process.env.REACT_APP_API_URL;
    if (!tokened) {
      updateAuth(false);
    } else {
      fetch(`${apiUrl}/auth/validate-token`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokened}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // console.log(data);
          if (data.msg === "Valid token") {
            updateAuth(true);
            emailHandler(data.emailVerify);
            setProfiledetails(data.theuser);
          } else {
            localStorage.removeItem("req_saved_tokened_set");
            updateAuth(false);
          }
        });
    }
  }, []);

  const datafecther = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/products`);
    const data = await response.json();
    setProd(data);
  };

  //fetch a particular product
  const aproductHandler = async (id) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    setIsLoadAPI(true);
    const response = await fetch(`${apiUrl}/products/${id}`);
    const data = await response.json();
    setIsLoadAPI(false);
    setAprod(data);
  };

  //add current product to cart state
  const addCartHandler = async (id) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/products/${id}`);
    const data = await response.json();
    setCartlist([...cartlist, data]);
  };

  //auto update local storage with cart state and run the arithmetics
  useEffect(() => {
    const pricearray = cartlist.map(
      (item) => parseInt(item.price * item.itembuy)
      // parseInt(item.price.split(",").join(""))
    );
    const pricesum = pricearray.reduce((acc, curr) => acc + curr, 0);
    setSubtotal(pricesum);
    localStorage.setItem("cartLocal", JSON.stringify(cartlist));
  }, [cartlist]);

  // const total = subtotal + (subtotal * 5) / 100 + (subtotal * 0) / 100;

  //delete a product from cart local state
  const deleteCartHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      setCartlist(cartlist.filter((item) => item.id !== id));
    }
  };

  //for quantity in cartcard in local cart
  const quantHandler = (id, quant) => {
    // console.log("got here:", id, quant);
    setCartlist((cartlist) =>
      cartlist.map((item) =>
        item.id === id ? { ...item, itembuy: quant } : item
      )
    );
  };

  //moved local cart to server cart
  const MoveCartToServerHandler = async (cartlied) => {
    const cartNeed = cartlied.map(({ id: productId, itembuy: quantity }) => ({
      productId,
      quantity,
    }));
    // console.log(cartNeed);
    const tokened = localStorage.getItem("req_saved_tokened_set");
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/cart/cart-move-cloud`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokened}`,
      },
      body: JSON.stringify({ cartData: cartNeed }),
    });
    const data = await response.json();
    data.error
      ? alert("Error moving local cart to user account")
      : setCheckoutNumbers(data.thecheckout);
    setProfiledetails(data.theuser);
    localStorage.removeItem("cartLocal");
    const rescart = await fetch(`${apiUrl}/cart`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokened}`,
      },
    });
    const datacart = await rescart.json();
    datacart.error
      ? alert("Error getting cart from server")
      : setCartlistServer(datacart.thecartlist);
  };

  //reload the cart data from server
  const reloadcartserverhandler = async () => {
    const tokened = localStorage.getItem("req_saved_tokened_set");
    const apiUrl = process.env.REACT_APP_API_URL;
    const rescart = await fetch(`${apiUrl}/cart`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokened}`,
      },
    });
    const datacart = await rescart.json();
    datacart.error
      ? alert("Error getting cart from server")
      : setCartlistServer(datacart.thecartlist);
    setCheckoutNumbers(datacart.thecheckout);
  };

  useEffect(() => {
    if (isAuthenticated) {
      reloadcartserverhandler();
    }
  }, [isAuthenticated]);

  //add and change quantity of current product to server cart state
  const addCartServerHandler = async (aproduct) => {
    // const cartNeed = aproduct.map(({ id: productId, itembuy: quantity }) => ({
    //   productId,
    //   quantity,
    // }));
    // console.log(aproduct);
    const tokened = localStorage.getItem("req_saved_tokened_set");
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/cart/addproduct`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokened}`,
      },
      body: JSON.stringify({ cartData: aproduct }),
    });
    const data = await response.json();
    data.error
      ? alert("Error adding the product to cart")
      : setCartlistServer(data.thecartlist);
    setCheckoutNumbers(data.thecheckout);
  };

  //delete a product from server cart state
  const deleteCartServerHandler = async (id) => {
    const tokened = localStorage.getItem("req_saved_tokened_set");
    const apiUrl = process.env.REACT_APP_API_URL;
    if (window.confirm("Are you sure this cart?")) {
      const response = await fetch(`${apiUrl}/cart/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokened}`,
        },
      });
      const data = await response.json();
      data.error
        ? alert("Error delete cart from server")
        : setCartlistServer(data.thecartlist);
      setCheckoutNumbers(data.thecheckout);
    }
  };

  const createAnOrderHandler = async () => {
    setIsLoadAPI(true);
    const tokened = localStorage.getItem("req_saved_tokened_set");
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/order/pendingorder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokened}`,
      },
    });
    const data = await response.json();
    data.error ? alert("Error creating an order") : setAnOrder(data.aorder);
    setIsLoadAPI(false);
  };

  const chargeAnOrderHandler = async (orderID) => {
    const tokened = localStorage.getItem("req_saved_tokened_set");
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/payment/charge-pending-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokened}`,
      },
      body: JSON.stringify({ orderID: orderID }),
    });
    const data = await response.json();
    data.error
      ? alert("Error adding the product to cart")
      : (window.location.href = data.url);
  };

  const getAnOrderHandler = async (orderID) => {
    // console.log("order:", orderID);
    setIsLoadAPI(true);
    const tokened = localStorage.getItem("req_saved_tokened_set");
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/order/gettingorder/${orderID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokened}`,
      },
    });
    const data = await response.json();
    data.error ? alert("Error getting the order") : setGetOrder(data);
    setIsLoadAPI(false);
  };

  const getAllEverOrderHandler = async () => {
    setIsLoadAPI(true);
    const tokened = localStorage.getItem("req_saved_tokened_set");
    const apiUrl = process.env.REACT_APP_API_URL;
    const response = await fetch(`${apiUrl}/order/allsuccessfulorder`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokened}`,
      },
    });
    const data = await response.json();
    // console.log(data);
    data.error ? alert("Error getting all order") : setAllOrders(data);
    setIsLoadAPI(false);
  };

  //set isAuthenticated for protected routes
  const updateAuth = (value) => {
    setIsAuthenticated(value);
  };

  const emailHandler = (value) => {
    setEmailVerify(value);
  };

  return (
    <EcommerceContext.Provider
      value={{
        isLoadAPI,
        setIsLoadAPI,
        prod,
        aproductHandler,
        aprod,
        cartlist,
        addCartHandler,
        subtotal,
        // total,
        deleteCartHandler,
        quantHandler,
        // quant,
        // setQuant,
        loginMsg,
        setLoginMsg,
        isAuthenticated,
        updateAuth,
        auditCart,
        emailverify,
        setEmailVerify,
        emaillogged,
        setEmailLogged,
        MoveCartToServerHandler,
        checkoutNumbers,
        setCheckoutNumbers,
        cartlistServer,
        setCartlistServer,
        addCartServerHandler,
        deleteCartServerHandler,
        profiledetails,
        setProfiledetails,
        emailHandler,
        createAnOrderHandler,
        anOrder,
        chargeAnOrderHandler,
        getAnOrderHandler,
        getOrder,
        reloadcartserverhandler,
        getAllEverOrderHandler,
        allOrders,
      }}
    >
      {children}
    </EcommerceContext.Provider>
  );
};

export default EcommerceContext;
