import { useContext, useState } from "react";
import VerifyOTP from "./pages/VerifyOTP";
import EcommerceContext from "../context/EcommerceContext";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
// import { CountrySelect, StateSelect } from "react-country-state-city";
// import "react-country-state-city/dist/react-country-state-city.css";

function Registerprofile() {
  const navigate = useNavigate();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [addressline, setAddressline] = useState("");
  //   const [countryid, setCountryid] = useState(0);
  const [countryname, setCountryname] = useState("");
  //   const [stateid, setstateid] = useState(0);
  const [statename, setstatename] = useState("");
  const [cityname, setcityname] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMess, setErrorMess] = useState("");
  const { emailverify, setProfiledetails } = useContext(EcommerceContext);

  const handleZipcodeChange = (e) => {
    const inputValue = e.target.value;
    // Regular expression to match only numbers
    const regex = /^[0-9\b]+$/;
    if (
      inputValue === "" ||
      (regex.test(inputValue) && inputValue.length <= 6)
    ) {
      setZipcode(inputValue);
    }
  };

  const handleMobileChange = (e) => {
    const inputVal = e.target.value;
    // Regular expression to match only numbers
    const regex = /^[0-9\b]+$/;
    if (inputVal === "" || (regex.test(inputVal) && inputVal.length <= 11)) {
      setPhonenumber(inputVal);
    }
  };

  const createFormHandler = async (e) => {
    setIsLoading(true);
    const tokened = localStorage.getItem("req_saved_tokened_set");
    const apiUrl = process.env.REACT_APP_API_URL;
    e.preventDefault();
    const response = await fetch(`${apiUrl}/profile/profileupdate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokened}`,
      },
      body: JSON.stringify({
        firstname,
        lastname,
        phonenumber,
        addressline,
        cityname,
        statename,
        countryname,
        zipcode,
      }),
    });

    const data = await response.json();

    if (data.theuser) {
      setProfiledetails(data.theuser);
      // console.log(data.theuser);
      setTimeout(() => {
        setIsLoading(false);
        setFirstname("");
        setLastname("");
        setPhonenumber("");
        setAddressline("");
        setcityname("");
        setstatename("");
        setCountryname("");
        setZipcode("");
        navigate("/cart");
      }, 2000);
    } else {
      setTimeout(() => {
        setErrorMess("Error updating, try again or Click page logo");
        // setFirstname("");
        // setLastname("");
        // setPhonenumber("");
        // setAddressline("");
        // setcityname("");
        // setstatename("");
        // setCountryname("");
        // setZipcode("");
        return setIsLoading(false);
      }, 2000);
    }
  };

  // console.log(emailverify);

  return (
    <div className="registerpropage">
      {emailverify ? null : <VerifyOTP />}
      <div className="regpropagecontainer">
        <img src="/test/loginlogo.png" alt="Login logo" />
        <h2>Update your Profile</h2>
        <form onSubmit={createFormHandler}>
          <input
            onChange={(e) => setFirstname(e.target.value)}
            type="text"
            value={firstname}
            required
            placeholder="  First Name"
          />
          <input
            onChange={(e) => setLastname(e.target.value)}
            type="text"
            value={lastname}
            required
            placeholder="  Last Name"
          />
          <input
            onChange={handleMobileChange}
            type="text"
            value={phonenumber}
            required
            placeholder="  Phone number"
          />
          <input
            onChange={(e) => setAddressline(e.target.value)}
            type="text"
            value={addressline}
            required
            placeholder="  Address Line"
          />
          <input
            onChange={(e) => setcityname(e.target.value)}
            type="text"
            value={cityname}
            required
            placeholder="  City"
          />
          <input
            onChange={(e) => setstatename(e.target.value)}
            type="text"
            value={statename}
            required
            placeholder="  State"
          />
          <input
            onChange={(e) => setCountryname(e.target.value)}
            type="text"
            value={countryname}
            required
            placeholder="  Country"
          />
          <input
            onChange={handleZipcodeChange}
            type="text"
            value={zipcode}
            required
            placeholder="  Zip code"
          />
          <button className="regprobtnsubmit">
            {isLoading ? (
              <TailSpin
                height="25"
                width="25"
                color="#808080"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{
                  width: "25px",
                  margin: "auto",
                  fontWeight: "bolder",
                }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              "Update"
            )}
          </button>
          <p>{errorMess}</p>
        </form>
      </div>
    </div>
  );
}

export default Registerprofile;

// <div className="Signuppage">
//   <div className="uppagecontainer">
//     <img src="/test/loginlogo.png" alt="Login logo" />
//     <h2>Create an Account</h2>
//     <form onSubmit={createFormHandler}>
//       <input
//         onChange={(e) => setFirstname(e.target.value)}
//         type="text"
//         value={firstname}
//         placeholder="  First Name"
//       />
//       <input
//         onChange={(e) => setLastname(e.target.value)}
//         type="text"
//         value={lastname}
//         placeholder="  Last Name"
//       />
//       <input
//         onChange={(e) => setAddressline(e.target.value)}
//         type="text"
//         value={addressline}
//         placeholder="  Address Line"
//       />
//       <h6>Country</h6>
//       <CountrySelect
//         onChange={(e) => {
//           setCountryid(e.id);
//           setCountryname(e.name);
//         }}
//         placeHolder="Select Country"
//       />
//       <h6>State</h6>
//       <StateSelect
//         countryid={countryid}
//         onChange={(e) => {
//           setstateid(e.id);
//           setstatename(e.name);
//         }}
//         placeHolder="Select State"
//       />
//       {/* <h6>City</h6>
//       <CitySelect
//         countryid={countryid}
//         stateid={stateid}
//         onChange={(e) => {
//           setcityname(e.target.value);
//         }}
//         placeHolder="Select City"
//       /> */}
//       <input
//         onChange={(e) => setcityname(e.target.value)}
//         type="text"
//         value={cityname}
//         placeholder="  City"
//       />
//       <button className="upbtnsubmit">Sign up</button>
//     </form>
//   </div>
// </div>
