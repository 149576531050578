import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import EcommerceContext from "../../context/EcommerceContext";
// import VerifyOTP from "../pages/VerifyOTP";

function ProtectedRoutes() {
  const { isAuthenticated, updateAuth, emailHandler } =
    useContext(EcommerceContext);
  const location = useLocation();
  const tokened = localStorage.getItem("req_saved_tokened_set");
  const apiUrl = process.env.REACT_APP_API_URL;
  if (!tokened) {
    updateAuth(false);
  } else {
    fetch(`${apiUrl}/auth/validate-token`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokened}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        if (data.msg === "Valid token") {
          updateAuth(true);
          emailHandler(data.emailVerify);
          // profileHandler(data.theuser);
          // setProfiledetails(data.theuser);
        } else {
          localStorage.removeItem("req_saved_tokened_set");
          updateAuth(false);
        }
      });
  }

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" state={{ from: location }} />
  );
}

export default ProtectedRoutes;
