import AllproductsDisplay from "./AllproductsDisplay";
import EcommerceContext from "../context/EcommerceContext";
import { useContext } from "react";

function Allproducts() {
  const { prod } = useContext(EcommerceContext);
  return (
    <div className="allProductSectionBack">
      <div className="allProductSection">
        {prod.map((item) => (
          <AllproductsDisplay key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}

export default Allproducts;
