import { NavLink } from "react-router-dom";
import { MdFacebook } from "react-icons/md";
import {
  RiTwitterXFill,
  RiInstagramLine,
  RiLinkedinBoxLine,
} from "react-icons/ri";

function Footer() {
  return (
    <div className="footer">
      <div className="footercontainer">
        <div className="footercard">
          <div>
            <h3>Need help?</h3>
            <p>
              <NavLink className="footerpee" to="/help-faq">
                FAQs
              </NavLink>
            </p>
            <p>
              <NavLink className="footerpee" to="/contactus">
                Help Center
              </NavLink>
            </p>
            <p>
              <NavLink className="footerpee" to="/contactus">
                Contact Us
              </NavLink>
            </p>
          </div>
          <div>
            <h3>Join us on</h3>
            <span>
              <MdFacebook className="sociallogo" />
            </span>
            <span>
              <RiTwitterXFill className="sociallogo" />
            </span>
            <span>
              <RiInstagramLine className="sociallogo" />
            </span>
            <span>
              <RiLinkedinBoxLine className="sociallogo" />
            </span>
          </div>
        </div>
        <div className="footercard">
          <h3>About Music Shop</h3>
          <p>
            <NavLink className="footerpee" to="/aboutus">
              About Us
            </NavLink>
          </p>
          <p>Advertise with Us</p>
          <p>
            Address: First floor, 2B Yinusa Adeniji street, opposite St. James
            church, Unity road, Off Toyin street, Ikeja, Lagos, Nigeria.
          </p>
        </div>
        <div className="footercard">
          <h3>Products</h3>
          <p>
            <NavLink className="footerpee" to="/allproducts">
              All products
            </NavLink>
          </p>
          <p>
            <NavLink className="footerpee" to="/">
              Featured products
            </NavLink>
          </p>
          <p>
            <NavLink className="footerpee" to="/">
              All brands
            </NavLink>
          </p>
        </div>
        <div className="footercard">
          <h3>Your Spaces</h3>
          {/* <p>
            <NavLink className="footerpee" to="/orders">
              Your orders
            </NavLink>
          </p> */}
          <p>
            <NavLink className="footerpee" to="/cart">
              Your cart
            </NavLink>
          </p>
          <p>Saved items</p>
          <p>Return Policy</p>
          <p>Language</p>
        </div>
      </div>
      <div className="backtotop">
        <p>Back to top</p>
      </div>
    </div>
  );
}

export default Footer;
