import { useContext } from "react";
import EcommerceContext from "../context/EcommerceContext";
// import { NavLink } from "react-router-dom";
import Loader from "./shared/Loader";
import { TbCurrencyNaira } from "react-icons/tb";
import { IoMdArrowBack } from "react-icons/io";
import { TailSpin } from "react-loader-spinner";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useParams } from "react-router-dom";

function Product() {
  const navigate = useNavigate();
  // const params = useParams();
  const {
    aprod,
    addCartHandler,
    isLoadAPI,
    cartlist,
    isAuthenticated,
    cartlistServer,
    quantHandler,
    addCartServerHandler,
    // aproductHandler,
    // setIsLoadAPI,
  } = useContext(EcommerceContext);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   aproductHandler(params.id);
  // }, []);

  // if (window.location.reload === true) {
  //   setIsLoadAPI(false);
  // }

  const fromserverlist = cartlistServer.cartlist;

  let cartFill;

  if (isAuthenticated) {
    cartFill = fromserverlist;
  } else {
    cartFill = cartlist;
  }
  // console.log(cartFill);

  const isInCart = cartFill.some((item) => item.id === aprod.id);
  const currQuant =
    cartFill.filter((item) => item.id === aprod.id && item.itembuy)[0]
      ?.itembuy || 1;
  // console.log("curr:", isInCart, currQuant);

  const addCartMotionAllhandler = () => {
    const aproduct = {
      productId: aprod.id,
      quantity: currQuant,
    };
    setIsLoading(true);
    setTimeout(() => {
      if (isAuthenticated) {
        addCartServerHandler(aproduct);
      } else {
        addCartHandler(aprod.id);
      }
    }, 1000);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const decreaseHandler = () => {
    // console.log("got here -", currQuant);
    if (currQuant > 1 && currQuant <= 10) {
      const aprodu = {
        productId: aprod.id,
        quantity: currQuant - 1,
      };
      if (isAuthenticated) {
        return addCartServerHandler(aprodu);
      } else {
        return quantHandler(aprod.id, currQuant - 1);
      }
    }
  };

  const increaseHandler = () => {
    // console.log("got here +", currQuant);
    if (currQuant >= 1 && currQuant < 10) {
      const aprodu = {
        productId: aprod.id,
        quantity: currQuant + 1,
      };
      if (isAuthenticated) {
        return addCartServerHandler(aprodu);
      } else {
        return quantHandler(aprod.id, currQuant + 1);
      }
    }
  };

  return (
    <div className="product">
      {/* <NavLink to="/"> */}
      <p className="backarrow" onClick={() => navigate(-1)}>
        <IoMdArrowBack />
        Back
      </p>
      {/* </NavLink> */}
      {isLoadAPI ? (
        <div
          style={{
            width: "100px",
            margin: "auto",
          }}
        >
          <Loader loadColor={"#202142"} />
        </div>
      ) : (
        <div className="productcontainer">
          <div className="productdetails">
            <img
              src={aprod.image}
              alt={aprod.productname}
              // width={300}
              // height={300}
            />
            <div className="producttext">
              <h1>{aprod.productname}</h1>
              <h4>{aprod.brand}</h4>
              <h2>
                <span>
                  <TbCurrencyNaira className="tbcurrency" />
                </span>
                {aprod.price}
              </h2>
              <h3>Description</h3>
              <p>{aprod.description}</p>
              {isInCart ? (
                <span className="isincart">
                  <button onClick={decreaseHandler} className="btnnum">
                    -
                  </button>
                  <p>{currQuant}</p>
                  <button onClick={increaseHandler} className="btnnum">
                    +
                  </button>
                </span>
              ) : (
                <button onClick={addCartMotionAllhandler}>
                  {isLoading ? (
                    <TailSpin
                      height="25"
                      width="25"
                      color="#000"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{
                        width: "100px",
                        margin: "auto",
                        fontWeight: "bolder",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : (
                    "Add to Cart"
                  )}
                </button>
              )}
            </div>
          </div>
          <div className="shoppingdetails">
            <h3>Shopping Details</h3>
            <div>
              <h4>Choose your location</h4>
              <select>
                <option>Lagos</option>
                <option>Abia</option>
                <option>Abuja</option>
                <option>Port harcourt</option>
                <option>Enugu</option>
                <option>Kano</option>
                <option>Sokoto</option>
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Product;
