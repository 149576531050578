import EcommerceContext from "../../context/EcommerceContext";
import { useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";

function SignoutButton() {
  const navigate = useNavigate();
  const {
    setEmailVerify,
    updateAuth,
    setCartlistServer,
    setCheckoutNumbers,
    setProfiledetails,
  } = useContext(EcommerceContext);

  const signoutHandler = () => {
    setTimeout(() => {
      setEmailVerify(Boolean);
      localStorage.clear();
      updateAuth(false);
      setCartlistServer({});
      setCheckoutNumbers({});
      setProfiledetails({});
      navigate("/");
    }, 1000);
  };

  return (
    <div className="signout">
      <div className="signoutcontainer">
        <h4>Are you sure you want to Logout?</h4>
        <NavLink to="/">
          <button className="btnsubmit">Back to Uptuned</button>
        </NavLink>
        <button className="btnsubmitred" onClick={signoutHandler}>
          Sign Out
        </button>
      </div>
    </div>
  );
}

export default SignoutButton;
