import { useState } from "react";
// import EcommerceContext from "../context/EcommerceContext";
import { TbCurrencyNaira } from "react-icons/tb";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import AllOrderedDisplay from "./AllOrderedDisplay";

function OrdersAllDisplay({ item }) {
  const [orderslide, setOrderslide] = useState(false);
  // const { profiledetails } = useContext(EcommerceContext);
  const orderlist = item.orderItems;

  return (
    <>
      <div className="ordered" onClick={() => setOrderslide(!orderslide)}>
        <div className="ordereddetail">
          {/* <img src={item.image} alt={item.productname} /> */}
          <div>
            <h3>Order Number: {item.id}</h3>
            <h4>status: {item.transactionstatus}</h4>
            <p>Number of Items: {item.itemnumber}</p>
            <p>Ordered Date: {item.createdAt}</p>
            <p>Shipped Address: {item.shippedaddress}</p>
          </div>
        </div>
        <div className="orderedprice">
          <h4>
            <TbCurrencyNaira className="tbcartcard" size={"1.5rem"} />
            {item.totalprice}
          </h4>
          <h4>{orderslide ? <IoIosArrowUp /> : <IoIosArrowDown />} More...</h4>
        </div>
      </div>
      {orderslide && (
        <div className="ordereddetailed">
          {orderlist.map((tem) => (
            <AllOrderedDisplay key={tem.id} item={tem} />
          ))}
        </div>
      )}
    </>
  );
}

export default OrdersAllDisplay;
