import { NavLink } from "react-router-dom";
import EcommerceContext from "../../context/EcommerceContext";
import { useContext } from "react";

function Accountslide() {
  const { isAuthenticated, getAllEverOrderHandler } =
    useContext(EcommerceContext);

  return (
    <div className="accountslide">
      {isAuthenticated ? (
        <>
          <div className="accountaccess">
            <NavLink to="/profiledetails">
              <button>Profile</button>
            </NavLink>
          </div>
          <div className="accountaccess">
            <NavLink to="/orders">
              <button onClick={() => getAllEverOrderHandler()}>Orders</button>
            </NavLink>
          </div>
          <div className="accountaccess">
            <NavLink to="/signingout">
              <button>Sign Out</button>
            </NavLink>
          </div>
        </>
      ) : (
        <>
          <div className="accountsignin">
            <NavLink to="/signin">
              <button>SIGN IN</button>
            </NavLink>
          </div>
          <p>
            Are you a new customer?{" "}
            <NavLink to="/signup">
              <span className="accountsignup">Sign Up</span>
            </NavLink>
          </p>
        </>
      )}
    </div>
  );
}

export default Accountslide;
