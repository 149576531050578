import Header from "./components/Header";
import Headerlist from "./components/shared/Headerlist";
import ProductSection from "./components/ProductSection";
import Brands from "./components/Brands";
import Footer from "./components/Footer";
import Product from "./components/Product";
import Cart from "./components/Cart";
import Checkout from "./components/Checkout";
import SigninPage from "./components/SigninPage";
import SignupPage from "./components/SignupPage";
import Allproductline from "./components/shared/AllProductline";
import Allproducts from "./components/Allproducts";
import Registerprofile from "./components/Registerprofile";
import Hamburger from "./components/shared/Hamburger";
import Searchinput from "./components/shared/Searchinput";
import ProtectedRoutes from "./components/Routes/ProtectedRoutes";
import ProfileDetails from "./components/shared/ProfileDetails";
import InOrder from "./components/InOrder";
import PayLoading from "./components/shared/PayLoading";
import PaymentStatus from "./components/shared/PaymentStatus";
import OrderedReciept from "./components/OrderedReciept";
import Orders from "./components/pages/Orders";
import SignoutButton from "./components/shared/SignoutButton";
import ContactUs from "./components/ContactUs";
import AboutUs from "./components/AboutUs";
import HelpPage from "./components/HelpPage";
//
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { EcommerceProvider } from "./context/EcommerceContext";
import { useState, useEffect } from "react";
import Loader from "./components/shared/Loader";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <EcommerceProvider>
      <BrowserRouter>
        <div className="App">
          <div className="headerback">
            <div className="header">
              <Header />
              <Headerlist />
              <Hamburger />
            </div>
            <div className="searchmobile">
              <Searchinput />
            </div>
          </div>
          {isLoading ? (
            <div
              style={{
                width: "100px",
                margin: "auto",
              }}
            >
              <Loader loadColor={"#fff"} />
            </div>
          ) : (
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Allproductline />
                    <ProductSection />
                    <Brands />
                  </>
                }
              />
              <Route
                path="/product/:id"
                element={
                  <>
                    <Product />
                  </>
                }
              />
              <Route
                path="/allproducts"
                element={
                  <>
                    <Allproducts />
                  </>
                }
              />
              <Route
                path="/cart"
                element={
                  <>
                    <Cart />
                    <Brands />
                  </>
                }
              />
              <Route
                path="/signin"
                element={
                  <>
                    <SigninPage />
                  </>
                }
              />
              <Route
                path="/signup"
                element={
                  <>
                    <SignupPage />
                  </>
                }
              />
              <Route
                path="/contactus"
                element={
                  <>
                    <ContactUs />
                  </>
                }
              />
              <Route
                path="/aboutus"
                element={
                  <>
                    <AboutUs />
                  </>
                }
              />
              <Route
                path="/help-faq"
                element={
                  <>
                    <HelpPage />
                  </>
                }
              />
              <Route element={<ProtectedRoutes />}>
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/registerprofile" element={<Registerprofile />} />
                <Route path="/profiledetails" element={<ProfileDetails />} />
                <Route path="/inorder" element={<InOrder />} />
                <Route path="/payloading" element={<PayLoading />} />
                <Route path="/paymentstatus" element={<PaymentStatus />} />
                <Route path="/orderedreciept" element={<OrderedReciept />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/signingout" element={<SignoutButton />} />
              </Route>
            </Routes>
          )}
          <Footer />
        </div>
      </BrowserRouter>
    </EcommerceProvider>
  );
}

export default App;
