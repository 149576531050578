import { useContext } from "react";
import EcommerceContext from "../context/EcommerceContext";
import { NavLink } from "react-router-dom";
import { TbCurrencyNaira } from "react-icons/tb";
import Cartcard from "./Cartcard";
import Emptycart from "./shared/EmptyCart";

function Cart() {
  const { cartlist, subtotal, cartlistServer, isAuthenticated } =
    useContext(EcommerceContext);

  const fromserverlist = cartlistServer.cartlist;

  let cartFill;

  if (isAuthenticated) {
    cartFill = fromserverlist;
  } else {
    cartFill = cartlist;
  }

  return (
    <div className="cart">
      <div className="cartcontainer">
        <div className="cartdetails">
          <h2>Shopping Cart</h2>
          {cartFill.length < 1 ? (
            <Emptycart />
          ) : (
            cartFill.map((item) => <Cartcard key={item.id} item={item} />)
          )}
        </div>
        <div className="cartsummary">
          <h2>Cart Summary</h2>
          <div className="cartsubtotal">
            <h4>Subtotal</h4>
            <h4>
              <TbCurrencyNaira className="tbsummary" size={"1.5rem"} />
              {isAuthenticated ? cartlistServer.carttotal : subtotal}
            </h4>
          </div>
          <NavLink to="/checkout">
            <button disabled={cartFill.length < 1 ? "disabled" : ""}>
              Proceed to checkout
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Cart;
