import { useState } from "react";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { useContext } from "react";
import EcommerceContext from "../context/EcommerceContext";
import { TailSpin } from "react-loader-spinner";

function SigninPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const {
    loginMsg,
    setLoginMsg,
    updateAuth,
    MoveCartToServerHandler,
    cartlist,
    emailHandler,
  } = useContext(EcommerceContext);
  const [isLoading, setIsLoading] = useState(false);
  const errorMsg = localStorage.getItem("errorMsg");

  const loginFormHandler = async (e) => {
    setIsLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    e.preventDefault();
    const response = await fetch(`${apiUrl}/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    const data = await response.json();
    if (data.access_token) {
      localStorage.setItem("req_saved_tokened_set", data.access_token);
      // localStorage.setItem("req_saved_email_verified", data.emailVerify);
      emailHandler(data.emailVerify);
      updateAuth(true);
      setTimeout(() => {
        setIsLoading(false);
        setEmail("");
        setPassword("");
        MoveCartToServerHandler(cartlist);
        const from = location.state?.from || "/";
        navigate(from);
      }, 2000);
    } else {
      setTimeout(() => {
        // console.log(data.msg);
        localStorage.setItem("errorMsg", data.msg);
        setLoginMsg(true);
        setEmail("");
        setPassword("");
        return setIsLoading(false);
      }, 2000);
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="Signinpage">
      <div className="pagecontainer">
        <img src="/test/loginlogo.png" alt="Login logo" />
        <h2>Login</h2>
        <form onSubmit={loginFormHandler}>
          <input
            onChange={(e) => setEmail(e.target.value)}
            onClick={() => setLoginMsg(false)}
            type="text"
            value={email}
            placeholder="  Email"
          />
          <div className="passeye" onClick={toggleMenu}>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type={isOpen ? "password" : "text"}
              value={password}
              placeholder="  Password"
            />
            {isOpen ? (
              <IoMdEyeOff size={"1.5rem"} color={"#202142"} />
            ) : (
              <IoMdEye size={"1.5rem"} color={"#202142"} />
            )}
          </div>
          <button className="btnsubmit">
            {isLoading ? (
              <TailSpin
                height="25"
                width="25"
                color="#808080"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{
                  width: "25px",
                  margin: "auto",
                  fontWeight: "bolder",
                }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              "Sign in"
            )}
          </button>
          <p>
            Are you a new customer?{" "}
            <NavLink to="/signup">
              <span className="signsignup">Sign Up</span>
            </NavLink>
          </p>
          <span style={{ color: "red", fontWeight: "bold" }}>
            <p>{loginMsg ? errorMsg : null}</p>
          </span>
        </form>
      </div>
    </div>
  );
}

export default SigninPage;
