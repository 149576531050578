import { TbCurrencyNaira } from "react-icons/tb";

function AllOrderedDisplay({ item }) {
  return (
    <div className="orderedsettwo">
      <p>
        {item.product.productname} X {item.quantity}
      </p>
      <p>
        <TbCurrencyNaira className="tbcartcard" size={"1rem"} />
        {item.product.price}
      </p>
    </div>
  );
}

export default AllOrderedDisplay;
