import Abrand from "./Abrand";
import fender from "../data/images/brands/Fender.jpeg";
import gibson from "../data/images/brands/gibson.png";
import harman from "../data/images/brands/harman.jpeg";
import mapex from "../data/images/brands/mapex.jpeg";
import martin from "../data/images/brands/martin.png";
import roland from "../data/images/brands/roland.png";
import shure from "../data/images/brands/shure.jpeg";
import steinway from "../data/images/brands/steinway.jpeg";
import yamaha from "../data/images/brands/yamaha.jpeg";
import blackstar from "../data/images/brands/blackstar.jpeg";
import gretsch from "../data/images/brands/gretsch.jpeg";
import kaliaudio from "../data/images/brands/kaliaudio.jpeg";
import kawai from "../data/images/brands/kawai.png";
import seagull from "../data/images/brands/seagull.png";
import taylor from "../data/images/brands/taylor.jpeg";

function Brands() {
  return (
    <div className="brands">
      <div className="banner">
        <p>Brands</p>
      </div>
      <div className="abrand">
        <Abrand source={fender} />
        <Abrand source={gibson} />
        <Abrand source={harman} />
        <Abrand source={mapex} />
        <Abrand source={martin} />
        <Abrand source={roland} />
        <Abrand source={shure} />
        <Abrand source={steinway} />
        <Abrand source={blackstar} />
        <Abrand source={gretsch} />
        <Abrand source={kaliaudio} />
        <Abrand source={kawai} />
        <Abrand source={seagull} />
        <Abrand source={taylor} />
        <Abrand source={yamaha} />
      </div>
    </div>
  );
}

export default Brands;
