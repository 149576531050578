import { NavLink } from "react-router-dom";

function Helpslide() {
  return (
    <div className="accountslide">
      <div className="accountaccess">
        <NavLink to="/aboutus">
          <button>About Us</button>
        </NavLink>
      </div>
      <div className="accountaccess">
        <NavLink to="/contactus">
          <button>Contact Us</button>
        </NavLink>
      </div>
      <div className="accountaccess">
        <NavLink to="/help-faq">
          <button>Help - FAQ</button>
        </NavLink>
      </div>
    </div>
  );
}

export default Helpslide;
