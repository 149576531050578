import { useState, useContext } from "react";
import { GrClose } from "react-icons/gr";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import EcommerceContext from "../../context/EcommerceContext";
import { FaCartArrowDown } from "react-icons/fa6";
import Burgerslide from "./Burgerslide";

function Hamburger() {
  const [isOpen, setIsOpen] = useState(false);
  const { cartlist, isAuthenticated, cartlistServer } =
    useContext(EcommerceContext);

  const fromserverlist = cartlistServer.cartlist;

  let cartFill = [];

  if (isAuthenticated) {
    cartFill = fromserverlist || [];
  } else {
    cartFill = cartlist || [];
  }

  return (
    // <div
    //   className={`hamburger-menu ${isOpen ? "open" : ""}`}
    //   onClick={toggleMenu}
    // >
    //   {/* <div className="bar"></div>
    //   <div className="bar"></div>
    //   <div className="bar"></div> */}
    //   <GrClose />
    // </div>
    <ul className="hamburger-menu">
      <li>
        <NavLink to="/cart">
          <FaCartArrowDown className="headericonsize" size={"2.5rem"} />
          <span className="cartnumber">
            {cartFill.length > 0 && cartFill.length}
          </span>
          <span>Cart</span>
        </NavLink>
      </li>
      <li onClick={() => setIsOpen(!isOpen)}>
        <NavLink>
          {isOpen ? (
            <GrClose className="openmenu" />
          ) : (
            <GiHamburgerMenu className="openmenu" />
          )}
        </NavLink>
        {isOpen && <Burgerslide />}
      </li>
    </ul>
  );
}

export default Hamburger;
