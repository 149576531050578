function HelpPage() {
  return (
    <div className="faq">
      <div className="faqcontainer">
        <h3>Coming Soon...</h3>
      </div>
    </div>
  );
}

export default HelpPage;
