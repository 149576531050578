import { useContext, useState } from "react";
import EcommerceContext from "../context/EcommerceContext";
import { TbCurrencyNaira } from "react-icons/tb";

function Cartcard({ item }) {
  const {
    deleteCartHandler,
    quantHandler,
    addCartServerHandler,
    isAuthenticated,
    deleteCartServerHandler,
  } = useContext(EcommerceContext);
  const [quantity, setQuantity] = useState(item.itembuy);

  const quantChangeHamdle = (e) => {
    setQuantity(Number(e.target.value));
    const aproduct = {
      productId: item.id,
      quantity: Number(e.target.value),
    };
    if (isAuthenticated) {
      addCartServerHandler(aproduct);
    } else {
      quantHandler(item.id, Number(e.target.value));
    }
    e.stopPropagation();
  };

  const motiondeletecartHandler = () => {
    if (isAuthenticated) {
      deleteCartServerHandler(item.id);
    } else {
      deleteCartHandler(item.id);
    }
  };

  return (
    <div className="carted">
      <div
        className="carteddetail"
        onChange={() => {
          quantHandler(item.id, quantity);
        }}
      >
        <img src={item.image} alt={item.productname} />
        <div>
          <h3>{item.productname}</h3>
          <p>{item.quantity > 0 && "In stock"}</p>
          <select
            onChange={quantChangeHamdle}
            value={quantity}
            className="cartedselect"
          >
            {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
              <option value={num} key={num}>
                {num}
              </option>
            ))}
            {/* <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
            <option value={9}>9</option>
            <option value={10}>10</option> */}
            {/* <option>10+</option> */}
          </select>
          <span>
            <button>Save for later</button>
          </span>
          <button onClick={motiondeletecartHandler}>Remove</button>
        </div>
      </div>
      <div className="cartedprice">
        <h4>
          <TbCurrencyNaira className="tbcartcard" size={"1.5rem"} />
          {item.price}
        </h4>
      </div>
    </div>
  );
}

export default Cartcard;
