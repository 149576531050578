import { TbCurrencyNaira } from "react-icons/tb";

function InOrderDisplay({ item }) {
  return (
    <div className="settwo">
      <p>
        {item.productname} X {item.itembuy}
      </p>
      <p>
        <TbCurrencyNaira className="tbcartcard" size={"1rem"} />
        {item.price}
      </p>
    </div>
  );
}

export default InOrderDisplay;
