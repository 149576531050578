import { useContext } from "react";
import EcommerceContext from "../../context/EcommerceContext";
import Loader from "../shared/Loader";
import OrdersAllDisplay from "../shared/OrdersAllDisplay";
import EmptyOrder from "../shared/EmptyOrder";

function Orders() {
  const { isLoadAPI, allOrders } = useContext(EcommerceContext);
  return (
    <div className="inorder">
      {isLoadAPI ? (
        <div
          style={{
            width: "100px",
            margin: "auto",
          }}
        >
          <Loader loadColor={"#202142"} />
        </div>
      ) : (
        <div className="orders">
          <div className="orderscontainer">
            <div className="ordersdetails">
              <h2>Orders</h2>
              {allOrders.length < 1 ? (
                <EmptyOrder />
              ) : (
                allOrders.map((item) => (
                  <OrdersAllDisplay key={item.id} item={item} />
                ))
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Orders;
