import { useState } from "react";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { useContext } from "react";
import EcommerceContext from "../context/EcommerceContext";

function SignupPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordedcheck, setpasswordedcheck] = useState("");
  const [mismatched, setMismatched] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenrpt, setIsOpenrpt] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    loginMsg,
    setLoginMsg,
    updateAuth,
    // setEmailVerify,
    setEmailLogged,
    MoveCartToServerHandler,
    cartlist,
    emailHandler,
  } = useContext(EcommerceContext);
  const userCreated = localStorage.getItem("user_created");

  const createFormHandler = async (e) => {
    setIsLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    e.preventDefault();
    if (password !== passwordedcheck) {
      setPassword("");
      setpasswordedcheck("");
      return setMismatched(true);
    }

    const response = await fetch(`${apiUrl}/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    const data = await response.json();

    if (response.ok) {
      localStorage.setItem("user_created", data.msg);
      setTimeout(() => {
        setLoginMsg(true);
      }, 2000);
    } else {
      localStorage.setItem("user_created", data.msg);
      setTimeout(() => {
        setLoginMsg(true);
        setIsLoading(false);
      }, 2000);
      return false;
    }

    const res = await fetch(`${apiUrl}/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    const authdata = await res.json();

    if (authdata.access_token) {
      localStorage.setItem("req_saved_tokened_set", authdata.access_token);
      // localStorage.setItem("req_saved_email_verified", authdata.emailVerify);
      emailHandler(authdata.emailVerify);
      updateAuth(true);
      // setEmailVerify(authdata.emailVerify);
      setEmailLogged(authdata.emailLogged);
      setTimeout(() => {
        setIsLoading(false);
        setEmail("");
        setPassword("");
        setpasswordedcheck("");
        MoveCartToServerHandler(cartlist);
        navigate("/registerprofile");
      }, 4000);
    } else {
      setTimeout(() => {
        // console.log(data.msg);
        localStorage.setItem("errorMsg", data.msg);
        setLoginMsg(true);
        setEmail("");
        setPassword("");
        setpasswordedcheck("");
        return setIsLoading(false);
      }, 4000);
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const toggleMenurpt = () => {
    setIsOpenrpt(!isOpenrpt);
  };

  return (
    <div className="Signuppage">
      <div className="uppagecontainer">
        <img src="/test/loginlogo.png" alt="Login logo" />
        <h2>Create an Account</h2>
        <form onSubmit={createFormHandler}>
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            value={email}
            required
            placeholder="  Email"
          />
          <div className="followeye" onClick={toggleMenu}>
            <input
              onChange={(e) => setPassword(e.target.value)}
              onClick={() => setMismatched(false)}
              type={isOpen ? "password" : "text"}
              value={password}
              required
              placeholder="  Password"
            />
            {isOpen ? (
              <IoMdEyeOff size={"1.5rem"} color={"#202142"} />
            ) : (
              <IoMdEye size={"1.5rem"} color={"#202142"} />
            )}
          </div>
          <div className="followeye" onClick={toggleMenurpt}>
            <input
              onChange={(e) => setpasswordedcheck(e.target.value)}
              type={isOpenrpt ? "password" : "text"}
              value={passwordedcheck}
              required
              placeholder="  Re-enter Password"
            />
            {isOpenrpt ? (
              <IoMdEyeOff size={"1.5rem"} color={"#202142"} />
            ) : (
              <IoMdEye size={"1.5rem"} color={"#202142"} />
            )}
          </div>
          <h6>Minimum 6 Characters</h6>
          <h6>Atleast 1 Uppercase, 1 Lowercase, 1 Number and 1 Symbol</h6>
          <h6>E.g. (Test@1) </h6>
          <button className="upbtnsubmit">
            {isLoading ? (
              <TailSpin
                height="25"
                width="25"
                color="#808080"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{
                  width: "25px",
                  margin: "auto",
                  fontWeight: "bolder",
                }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              "Sign up"
            )}
          </button>
          {mismatched ? <p>Password Mismatch, try again</p> : null}
          <p>{loginMsg ? userCreated : null}</p>
        </form>
      </div>
    </div>
  );
}

export default SignupPage;
