import storelogo from "../data/images/musicstorelogo.png";

function AboutUs() {
  return (
    <div className="about">
      <div className="aboutcontainer">
        <div className="aboutdetails">
          <h2>About Us</h2>
          <div className="imgcard">
            <img src={storelogo} alt="The Music Store Logo" />
          </div>
          <h3>History</h3>
          <p>
            It is shown by default, until the collapse plugin adds the
            appropriate classes that we use to style each element. These classes
            control the overall appearance, as well as the showing and hiding
            via CSS transitions. You can modify any of this with custom CSS or
            overriding our default variables. It's also worth noting that just
            about any HTML can go within the accordion-body, though the
            transition does limit overflow.
          </p>
          <h3>Vision</h3>
          <p>
            It is shown by default, until the collapse plugin adds the
            appropriate classes that we use to style each element. These classes
            control the overall appearance, as well as the showing and hiding
            via CSS transitions. You can modify any of this with custom CSS or
            overriding our default variables. It's also worth noting that just
            about any HTML can go within the accordion-body, though the
            transition does limit overflow.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
