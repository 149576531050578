import { useContext } from "react";
import EcommerceContext from "../context/EcommerceContext";
import { TbCurrencyNaira } from "react-icons/tb";
import Loader from "./shared/Loader";
import InOrderDisplay from "./shared/InOrderDisplay";
import { NavLink } from "react-router-dom";

function InOrder() {
  const {
    checkoutNumbers,
    profiledetails,
    chargeAnOrderHandler,
    isLoadAPI,
    anOrder,
    cartlistServer,
    setIsLoadAPI,
  } = useContext(EcommerceContext);

  setTimeout(() => {
    setIsLoadAPI(false);
  }, 4000);

  const fromserverlist = cartlistServer.cartlist;

  return (
    <div className="inorder">
      {isLoadAPI ? (
        <div
          style={{
            width: "100px",
            margin: "auto",
          }}
        >
          <Loader loadColor={"#202142"} />
        </div>
      ) : (
        <div className="inordercontainer">
          <div className="orderdetails">
            <h2>Order - {anOrder.id}</h2>
            <div className="setone">
              <h3>
                Status: <strong>{anOrder.transactionstatus}</strong>
              </h3>
              <p>
                Number of Items: <strong>{anOrder.itemnumber}</strong>
              </p>
              <p>
                Payment Method: <strong>{anOrder.paymentmethod}</strong>
              </p>
              <p>
                Delivery Method: <strong>{anOrder.deliverymethod}</strong>
              </p>
            </div>
            {fromserverlist.map((item) => (
              <InOrderDisplay key={item.id} item={item} />
            ))}

            <div className="setthree">
              <h5>Name </h5>
              <p>{`${profiledetails.firstName} ${profiledetails.lastName}`}</p>
              <h5>Mobile</h5>
              <p>{`${profiledetails.mobile}`}</p>
              <h5>Address </h5>
              <p>{`${profiledetails.addressLineOne}`}</p>
              <p>{`${profiledetails.city}, ${profiledetails.state}`}</p>
              <p>{`${profiledetails.country}, ${profiledetails.zipcode}.`}</p>
            </div>
          </div>
          <div className="paydetails">
            <h2>Payment</h2>
            <div className="producttotal">
              <h4>Subtotal</h4>
              <h4>
                <TbCurrencyNaira className="tbsubtotal" size={"1.5rem"} />
                {checkoutNumbers.subtotal}
              </h4>
            </div>
            <div className="producttotal">
              <p>Tax Charge (5%)</p>
              <p>
                <TbCurrencyNaira className="tbchargedis" size={"1rem"} />
                {checkoutNumbers.taxcharge}
              </p>
            </div>
            <div className="producttotal">
              <p>Discount(0%)</p>
              <p>
                <TbCurrencyNaira className="tbchargedis" size={"1rem"} />
                {checkoutNumbers.discount}
              </p>
            </div>
            <div className="producttotal">
              <p>Delivery Fee</p>
              <p>
                <TbCurrencyNaira className="tbchargedis" size={"1rem"} />
                {checkoutNumbers.deliveryfee}
              </p>
            </div>
            <div className="producttotal total">
              <h4>Total</h4>
              <h4>
                <TbCurrencyNaira className="tbtotaltotal" size={"1.5rem"} />
                {checkoutNumbers.totalamount}
              </h4>
            </div>
            <NavLink to="/payloading">
              <button
                className="btnsubmit"
                disabled={anOrder.id === undefined ? "disabled" : ""}
                onClick={() => chargeAnOrderHandler(anOrder.id)}
              >
                Pay with Paystack
              </button>
              {anOrder.id === undefined ? (
                <h4>Refresh Error: Reorder from cart</h4>
              ) : (
                ""
              )}
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
}

export default InOrder;
