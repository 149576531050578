import { useContext, useState } from "react";
import EcommerceContext from "../../context/EcommerceContext";
import { TailSpin } from "react-loader-spinner";

function ProfileDetails() {
  const { profiledetails, setProfiledetails } = useContext(EcommerceContext);
  const [phonenumber, setPhonenumber] = useState(profiledetails.mobile || "");
  const [addressline, setAddressline] = useState(profiledetails.addressLineOne);
  const [countryname, setCountryname] = useState(profiledetails.country);
  const [statename, setstatename] = useState(profiledetails.state);
  const [cityname, setcityname] = useState(profiledetails.city);
  const [zipcode, setZipcode] = useState(profiledetails.zipcode);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMess, setErrorMess] = useState("");
  const [editMode, setEditMode] = useState(false);

  const editProfileHandler = () => {
    setEditMode(true);
  };

  const cancelProfileHandler = () => {
    setEditMode(false);
  };

  const handleZipcodeChange = (e) => {
    const inputValue = e.target.value;
    // Regular expression to match only numbers
    const regex = /^[0-9\b]+$/;
    if (
      inputValue === "" ||
      (regex.test(inputValue) && inputValue.length <= 6)
    ) {
      setZipcode(inputValue);
    }
  };

  const handleMobileChange = (e) => {
    const inputVal = e.target.value;
    // Regular expression to match only numbers
    const regex = /^[0-9\b]+$/;
    if (inputVal === "" || (regex.test(inputVal) && inputVal.length <= 11)) {
      setPhonenumber(inputVal);
    }
  };

  const createFormHandler = async (e) => {
    setIsLoading(true);
    const tokened = localStorage.getItem("req_saved_tokened_set");
    const apiUrl = process.env.REACT_APP_API_URL;
    e.preventDefault();
    const response = await fetch(`${apiUrl}/profile/profileupdate/newupdate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokened}`,
      },
      body: JSON.stringify({
        phonenumber,
        addressline,
        cityname,
        statename,
        countryname,
        zipcode,
      }),
    });

    const data = await response.json();

    if (data.theuser) {
      setProfiledetails(data.theuser);
      // console.log(data.theuser);
      setTimeout(() => {
        setIsLoading(false);
        // setPhonenumber("");
        // setAddressline("");
        // setcityname("");
        // setstatename("");
        // setCountryname("");
        // setZipcode("");
        setEditMode(false);
      }, 2000);
    } else {
      setTimeout(() => {
        setErrorMess("Error updating, try again or Click page logo");
        return setIsLoading(false);
      }, 2000);
    }
  };

  return (
    <div className="profilepage">
      <div className="profilecontainer">
        <img src={profiledetails.avatar} alt="Login logo" />
        <h2>Profile</h2>
        <h3>First Name: {profiledetails.firstName}</h3>
        <h3>Last Name: {profiledetails.lastName}</h3>
        {editMode ? (
          <form name="editForm" onSubmit={createFormHandler}>
            <p>Mobile</p>
            <input
              onChange={handleMobileChange}
              type="text"
              value={phonenumber}
              required
              placeholder="  Phone number"
            />
            <p>Address Line</p>
            <input
              onChange={(e) => setAddressline(e.target.value)}
              type="text"
              value={addressline}
              required
              placeholder="  Address Line"
            />
            <p>City</p>
            <input
              onChange={(e) => setcityname(e.target.value)}
              type="text"
              value={cityname}
              required
              placeholder="  City"
            />
            <p>State</p>
            <input
              onChange={(e) => setstatename(e.target.value)}
              type="text"
              value={statename}
              required
              placeholder="  State"
            />
            <p>Country</p>
            <input
              onChange={(e) => setCountryname(e.target.value)}
              type="text"
              value={countryname}
              required
              placeholder="  Country"
            />
            <p>Zip code</p>
            <input
              onChange={handleZipcodeChange}
              type="text"
              value={zipcode}
              required
              placeholder="  Zip code"
            />
            <button className="profilebtnsubmit">
              {isLoading ? (
                <TailSpin
                  height="25"
                  width="25"
                  color="#808080"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{
                    width: "25px",
                    margin: "auto",
                    fontWeight: "bolder",
                  }}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                "Update"
              )}
            </button>
            <button
              type="button"
              className="profilebtnsubmit"
              onClick={cancelProfileHandler}
            >
              Cancel
            </button>
            <p>{errorMess}</p>
          </form>
        ) : (
          <>
            <h3>Mobile: {profiledetails.mobile}</h3>
            <h3>Address Line: {profiledetails.addressLineOne}</h3>
            <h3>City: {profiledetails.city}</h3>
            <h3>State: {profiledetails.state}</h3>
            <h3>Country: {profiledetails.country}</h3>
            <h3>Zip Code: {profiledetails.zipcode}</h3>
            <button className="profilebtnsubmit" onClick={editProfileHandler}>
              Edit Profile
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default ProfileDetails;

// {
/* <form onSubmit={createFormHandler}>
          <input
            onChange={handleMobileChange}
            type="text"
            value={phonenumber}
            required
            placeholder="  Phone number"
          />
          <input
            onChange={(e) => setAddressline(e.target.value)}
            type="text"
            value={addressline}
            required
            placeholder="  Address Line"
          />
          <input
            onChange={(e) => setcityname(e.target.value)}
            type="text"
            value={cityname}
            required
            placeholder="  City"
          />
          <input
            onChange={(e) => setstatename(e.target.value)}
            type="text"
            value={statename}
            required
            placeholder="  State"
          />
          <input
            onChange={(e) => setCountryname(e.target.value)}
            type="text"
            value={countryname}
            required
            placeholder="  Country"
          />
          <input
            onChange={handleZipcodeChange}
            type="text"
            value={zipcode}
            required
            placeholder="  Zip code"
          />
          <button className="regprobtnsubmit">
            {isLoading ? (
              <TailSpin
                height="25"
                width="25"
                color="#808080"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{
                  width: "25px",
                  margin: "auto",
                  fontWeight: "bolder",
                }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              "Update"
            )}
          </button>
          <p>{errorMess}</p>
        </form> */
// }
