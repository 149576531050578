import { BsCart2 } from "react-icons/bs";
import { NavLink } from "react-router-dom";

function EmptyOrder() {
  return (
    <div className="emptyorders">
      <BsCart2 className="emptyorderslogo" />
      <h3>Order is Empty.</h3>
      <h4>
        Click{" "}
        <NavLink to="/allproducts" style={{ color: "blue" }}>
          here
        </NavLink>{" "}
        to start shopping.
      </h4>
    </div>
  );
}

export default EmptyOrder;
