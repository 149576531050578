import { useContext } from "react";
import EcommerceContext from "../context/EcommerceContext";
import { TbCurrencyNaira } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import VerifyOTP from "./pages/VerifyOTP";
// import Loader from "./shared/Loader";

function Checkout() {
  const { checkoutNumbers, profiledetails, createAnOrderHandler, emailverify } =
    useContext(EcommerceContext);

  return (
    <>
      {emailverify ? null : <VerifyOTP />}
      <div className="checkout">
        {/* {isLoadAPI ? (
          <div
            style={{
              width: "100px",
              margin: "auto",
            }}
          >
            <Loader loadColor={"#202142"} />
          </div>
        ) : ( */}
        <div className="checkoutcontainer">
          <div className="shippingdetails">
            <h2>Shipping Address</h2>
            <h3>Name </h3>
            <h3>{`${profiledetails.firstName} ${profiledetails.lastName}`}</h3>
            <h3>Mobile</h3>
            <h3>{`${profiledetails.mobile}`}</h3>
            <h3>Address </h3>
            <h3>{`${profiledetails.addressLineOne}`}</h3>
            <h3>{`${profiledetails.city}, ${profiledetails.state}`}</h3>
            <h3>{`${profiledetails.country}, ${profiledetails.zipcode}.`}</h3>
            <NavLink to="/profiledetails">
              <button>Edit Address</button>
            </NavLink>
          </div>
          <div className="paydetails">
            <h2>Payment</h2>
            <div className="producttotal">
              <h4>Subtotal</h4>
              <h4>
                <TbCurrencyNaira className="tbsubtotal" size={"1.5rem"} />
                {checkoutNumbers.subtotal}
              </h4>
            </div>
            <div className="producttotal">
              <p>Tax Charge (5%)</p>
              <p>
                <TbCurrencyNaira className="tbchargedis" size={"1rem"} />
                {checkoutNumbers.taxcharge}
              </p>
            </div>
            <div className="producttotal">
              <p>Discount(0%)</p>
              <p>
                <TbCurrencyNaira className="tbchargedis" size={"1rem"} />
                {checkoutNumbers.discount}
              </p>
            </div>
            <div className="producttotal">
              <p>Delivery Fee</p>
              <p>
                <TbCurrencyNaira className="tbchargedis" size={"1rem"} />
                {checkoutNumbers.deliveryfee}
              </p>
            </div>
            <div className="producttotal total">
              <h4>Total</h4>
              <h4>
                <TbCurrencyNaira className="tbtotaltotal" size={"1.5rem"} />
                {checkoutNumbers.totalamount}
              </h4>
            </div>
            <NavLink to="/inorder">
              <button onClick={() => createAnOrderHandler()}>
                Click here to Order
              </button>
            </NavLink>
            <NavLink to="/cart">
              <button>Back to cart</button>
            </NavLink>
          </div>
        </div>
        {/* )} */}
      </div>
      <div className="advert">
        <p>Advertise with Us</p>
      </div>
    </>
  );
}

export default Checkout;
