import { NavLink } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { TailSpin } from "react-loader-spinner";
import EcommerceContext from "../../context/EcommerceContext";

function Searchinput() {
  const { aproductHandler } = useContext(EcommerceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [searchProduct, setSearchProduct] = useState([]);
  const [messMsg, setMessMsg] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  // const shouldRenderSelect = searchProduct && searchProduct.length > 0;

  useEffect(() => {
    //Query for product(s)
    const searchQueryHandler = async (query) => {
      try {
        setMessMsg("");
        setIsLoading(true);
        setSearchProduct([]);
        const apiUrl = process.env.REACT_APP_API_URL;
        // console.log(query);
        const response = await fetch(
          `${apiUrl}/products/search-query?query=` +
            encodeURIComponent(query.trim())
        );
        if (!response.ok) {
          // setSearchProduct([]);
          throw new Error("Something went wrong with Fetching Products");
        }
        const data = await response.json();
        if (data.length < 1) {
          // setSearchProduct([]);
          throw new Error("No related Product found yet, add more query");
        }
        setSearchProduct(data);
        // console.log(data);
        setIsLoading(false);
        setShowDropdown(true);
      } catch (error) {
        // console.log(error);
        setMessMsg(error.message);
        setIsLoading(false);
        setShowDropdown(true);
      }
    };

    if (query.length < 3) {
      setSearchProduct([]);
      setMessMsg("");
      setShowDropdown(false);
      return;
    }
    searchQueryHandler(query);
  }, [query]);

  const fixdropdown = (product) => {
    setSearchProduct([]);
    setMessMsg("");
    setShowDropdown(false);
    setQuery("");
    aproductHandler(product.id);
  };
  return (
    <div className="searchmobileblock">
      <div className="searchinput">
        <input
          onChange={(e) => setQuery(e.target.value)}
          type="text"
          value={query}
          placeholder="  Search Store"
        />
        <button>
          {isLoading ? (
            <TailSpin
              height="25"
              width="25"
              color="#808080"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{
                width: "25px",
                margin: "auto",
                fontWeight: "bolder",
              }}
              wrapperClass=""
              visible={true}
            />
          ) : (
            "Search"
          )}
        </button>
      </div>
      {showDropdown && (
        <div className="searchresult">
          {messMsg !== "" ? (
            <p>{messMsg}</p>
          ) : (
            <div>
              {searchProduct.map((product) => (
                <div key={product.id} className="searcharrange">
                  <NavLink to={`/product/${product.id}`}>
                    <p onClick={() => fixdropdown(product)}>
                      {product.productname}
                      <span>{product.brandname}</span>
                    </p>
                  </NavLink>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Searchinput;
