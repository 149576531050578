import { useContext } from "react";
import EcommerceContext from "../context/EcommerceContext";
// import { TbCurrencyNaira } from "react-icons/tb";
import Loader from "./shared/Loader";
import OrderedDisplay from "./shared/OrderedDisplay";
import { NavLink } from "react-router-dom";

function OrderedReciept() {
  const {
    // checkoutNumbers,
    profiledetails,
    // chargeAnOrderHandler,
    isLoadAPI,
    // anOrder,
    // cartlistServer,
    getOrder,
  } = useContext(EcommerceContext);
  //   console.log(getOrder);

  const fromserverlist = getOrder.orderItems;

  return (
    <div className="inorder">
      {isLoadAPI ? (
        <div
          style={{
            width: "100px",
            margin: "auto",
          }}
        >
          <Loader loadColor={"#202142"} />
        </div>
      ) : (
        <div className="inordercontainer">
          <div className="orderdetails">
            <h2>Receipt - {getOrder.id}</h2>
            <div className="setone">
              <h3>
                Status: <strong>{getOrder.transactionstatus}</strong>
              </h3>
              <p>
                Number of Items: <strong>{getOrder.itemnumber}</strong>
              </p>
              <p>
                Payment Method: <strong>{getOrder.paymentmethod}</strong>
              </p>
              <p>
                Delivery Method: <strong>{getOrder.deliverymethod}</strong>
              </p>
            </div>
            {fromserverlist.map((item) => (
              <OrderedDisplay key={item.id} item={item} />
            ))}

            <div className="setthree">
              <h5>Name </h5>
              <p>{`${profiledetails.firstName} ${profiledetails.lastName}`}</p>
              <h5>Mobile</h5>
              <p>{`${profiledetails.mobile}`}</p>
              <h5>Address </h5>
              <p>{`${profiledetails.addressLineOne}`}</p>
              <p>{`${profiledetails.city}, ${profiledetails.state}`}</p>
              <p>{`${profiledetails.country}, ${profiledetails.zipcode}.`}</p>
            </div>
          </div>
          <div className="paydetails">
            <h5>Receipt Sent to Email</h5>
            <NavLink to="/">
              <button
                className="btnsubmit"
                // onClick={() => chargeAnOrderHandler(anOrder.id)}
              >
                HomePage
              </button>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderedReciept;
